<template>
  <div class="about">
    <div class="abt-banner" style="background-image: url(/imgs/about1.jpg);background-size: cover;">
    </div>
    <div class="about-nav">
    	<div class="container">
			<div class="about-nav-inner">
				ABOUT US
			</div>
		</div>
	</div>
    <div class="section bg-gray">
    	<div class="container">
    		<h3 class="tt">Providing the Trusted Foundation for Crypto Mining Industry</h3>
    		<p class="pp mb40">BitFree Capital was founded in 2015 to serve the needs of the crypto mining industry. Our business comprises of OTC/Proprietary trading, Investments, building online/offline communities mainly in Hong Kong and Singapore.</p>
    		<div class="flex">
    			<div class="akd">
    				<img src="/imgs/matt-hoffman-Q0AM87PsYkE.jpg">
    			</div>
    			<div class="mb40">
    				<h3 class="tt">Our Mission</h3>
    				<p class="pp">To provide our customers with the trusted foundation for crypto mining industry.</p>
    				<h3 class="tt">
    					Our Vision
    				</h3>
    				<p class="pp">Powering Growth of Crypto Mining Industry, Make Crypto Currency Accessible to Everyone</p>
    			</div>
    		</div>
    	</div>
    </div>
  </div>
</template>
<script>
	export default{
		name:'About'
	}
</script>
<style lang="scss" scoped>
	.abt-banner{
		width: 100%;
		height: 300px;
		position: relative;
	}
	.about-nav{
		padding: 16px 30px;
		background-color: #fff;
		border-bottom: solid 1px #eee;
		font-size: 16px;
		font-weight: 600;
	}
	.tt{
		font-size: 28px;
		color: #212833;
		font-weight: 600;
	}
	.pp{
		font-size: 15px;
		color: #212833;
		line-height: 30px;
	}
	.akd{
		margin-right: 30px;
		img{
			max-width: 500px;
		}
	}
</style>
